<template>
  <footer class="bg-white border-t" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 pb-8 px-4 sm:px-6 lg:px-8">
      <div class="grid md:grid-cols-8 gap-8">
        <router-link class="md:col-span-2" to="/">
          <img class="mx-auto md:mx-0 w-auto" src="@/assets/logo-lg.svg" alt="Rescon" />
        </router-link>
        <div class="grid sm:grid-cols-3 sm:col-span-6 sm:col-start-3 gap-4">

          <!-- Navigation -->
          <div class="">
            <h3 class="mb-3 text-lg">Navigation</h3>
            <div class="space-y-1">
              <router-link
                v-for="route in quickLinks"
                :key="route.label"
                class="block underline w-max transition text-accent hover:text-accent-hover capitalize"
                :to="route.path"
                >{{ getFooterLabel(route.label) }}</router-link
              >
            </div>
          </div>
          <!-- Media Navigation -->
          <div class="">
            <h3 class="mb-3 text-lg">Media</h3>
            <div class="space-y-1">
              <router-link
                v-for="route in quickLinksMedia"
                :key="route.label"
                class="block underline w-max transition text-accent hover:text-accent-hover capitalize"
                :to="route.path"
                >{{ getFooterLabel(route.label) }}</router-link
              >
            </div>
          </div>
          
          <!-- Contact -->
          <div>
            <h3 class="mb-3 text-lg">Contact</h3>
            <div class="space-y-1">
              <address class="not-italic pb-2">
                25 North Rivermede Road, Unit 13 <br />
                Vaughan, Ontario L4K 5V4
              </address>
              <a
                href="tel:+1 905-760-7777"
                class="block underline w-max transition text-accent hover:text-accent-hover"
                >+1 905-760-7777
              </a>
              <!-- <a
                href="tel:+1 866-531-1608"
                class="block underline w-max transition text-accent hover:text-accent-hover"
                >+1 866-531-1608
              </a> -->
              <a
                href="mailto:media@rescon.com"
                class="block underline w-max transition text-accent hover:text-accent-hover"
                >media@rescon.com</a
              >
            </div>
            <div class="flex space-x-5 mt-4">
              <a target="_blank" href="https://twitter.com/_RESCON" @click.stop>
                <img src="@/assets/twitter.svg" alt="Twitter" />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/rescon-residential-construction-council-of-ontario/?originalSubdomain=ca" @click.stop>
                <img src="@/assets/linkedin.svg" alt="LinkedIn" />
              </a>
              <a target="_blank" href="https://www.instagram.com/resconontario" @click.stop>
                <img src="@/assets/instagram.svg" alt="Instagram" />
              </a>


            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class=" mt-8 py-6 border-t-4 border-accent bg-accent-lightest">
    <div class="lg:max-w-7xl space-y-4 lg:space-y-0 lg:mx-auto px-4 sm:px-6 lg:px-8 md:grid md:grid-cols-3">
      <div class="text-center md:text-left">
        <p class="text-base text-gray-700">&copy; Copyright {{ new Date().getFullYear() }} Rescon</p>
      </div>
      <div class="md:flex text-center items-center space-x-4 ">
        <a
          href="/privacy-policy"
          class="underline inline-block w-max transition text-accent hover:text-accent-hover md:ml-auto"
          >Privacy Policy</a
        >
        <a
          href="/terms-and-conditions"
          class="md:mr-auto sm:pl-4 border-gray-300 inline-block sm:border-l underline w-max transition text-accent hover:text-accent-hover"
          >Terms & Conditions</a
        >
      </div>
      <a href="https://airwhistle.com/" target="_blank" class="md:ml-auto text-gray-700 hover:text-accent my-auto block md:mx-0 mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 md:mx-0 mx-auto" viewBox="0 0 33 23" fill="currentColor">
          <path d="M0.502254 4.6875C0.28661 4.6875 0.205744 4.745 0.178789 4.77376C0.178789 4.77376 0.124878 4.80251 0.124878 4.91752C0.124878 5.98135 2.44304 8.48278 3.17084 8.48278H10.934L12.6052 4.6875H0.502254Z" fill="currentColor"/>
          <path d="M3.35967 9.20117C3.14403 9.20117 3.06316 9.25868 3.03621 9.28743C3.03621 9.28743 2.9823 9.31618 2.9823 9.43119C2.9823 10.495 5.30041 12.9964 6.05514 12.9964H8.96626L10.6375 9.20117H3.35967Z" fill="currentColor"/>
          <path d="M6.45936 13.7148C6.32459 13.7148 6.13591 13.7436 6.082 13.8586C5.97418 14.2611 6.59413 15.3825 7.48363 16.3313L8.61572 13.7436H6.45936V13.7148Z" fill="currentColor"/>
          <path d="M29.0209 8.48278C29.7756 8.48278 32.0938 5.98135 32.0938 4.91752C32.0938 4.80251 32.0668 4.77376 32.0398 4.77376C32.0129 4.745 31.932 4.6875 31.7164 4.6875H19.5867L21.177 8.48278H29.0209Z" fill="currentColor"/>
          <path d="M26.1637 12.9964C26.9185 12.9964 29.2097 10.495 29.2097 9.43119C29.2097 9.31618 29.1828 9.28743 29.1558 9.28743C29.1288 9.25868 29.048 9.20117 28.8323 9.20117H21.5004L23.0908 12.9964H26.1637Z" fill="currentColor"/>
          <path d="M26.0828 13.8586C26.0558 13.7436 25.8402 13.7148 25.7054 13.7148H23.3873L24.5464 16.4463C25.5167 15.4975 26.2175 14.2611 26.0828 13.8586Z" fill="currentColor"/>
          <path d="M16.1364 0L6.43262 22.2254H10.368L12.3897 17.51H19.5866L21.5004 22.2254H25.4897L16.1364 0ZM16.0555 8.85565L18.0771 13.801H13.953L16.0555 8.85565Z" fill="currentColor"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { navigation } from "@/data";
const quickLinks = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "About",
    path: "/about/overview",
  },
  {
    label: "Submissions & Reports",
    path: "/submissions",
  },
  {
    label: "Upcoming Events",
    path: "/events",
  },
  {
    label: "ORCCA",
    path: "/ORCCA",
  },
  {
    label: "Collective Agreements",
    path: "/collective-agreements",
  },
  {
    label: "contact",
    path: "/contact",
  },
];
const quickLinksMedia = [
  {
    label: "In The News",
    path: "/media/in-the-news",
  },
  {
    label: "Press Releases",
    path: "/media/press-releases",
  },
  {
    label: "Blog",
    path: "/media/blog",
  },
  {
    label: "Videos",
    path: "/media/videos",
  },
  {
    label: "Newsletters",
    path: "/media/newsletters",
  },
];
export default {
  setup() {
    return { navigation };
  },
  methods: {
    getFooterLabel(label) {
      switch (label) {
        case "about":
          return "About us";
        case "submissions":
          return "Submissions & Reports";
        default:
          return label;
      }
    },
  },
  props: {
    quickLinks: {
      type: Array,
      default: () => quickLinks,
    },
    
    quickLinksMedia: {
      type: Array,
      default: () => quickLinksMedia,
    }
  }
};
</script>
